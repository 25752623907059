import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { Button, Typography } from '@/components/structural';
import { AppLayout404 } from '@/features/layout';
const Custom404: NextPage = () => {
  const router = useRouter();
  const [backClicked, setBackClicked] = useState(false);
  const routeBack = () => {
    if (!backClicked) {
      router.back();
      setBackClicked(true);
    }
  };
  return <>
        <AppLayout404 pageTitle="Page Not Found" data-sentry-element="AppLayout404" data-sentry-source-file="404.tsx">
            <div className="min-w-screen min-h-screen bg-primary dark:bg-primary-dark bg-opacity-10 flex items-center p-5 lg:p-20 overflow-hidden relative">
                <div className="flex-1 min-h-full min-w-full rounded-3xl bg-white dark:bg-default-dark shadow-xl p-10 lg:p-20 relative md:flex items-center text-center md:text-left">
                    <div className="w-full md:w-1/2">
                        <div className="mb-10 md:mb-20">
                            <Typography.Title level={3} className="!font-normal" data-sentry-element="unknown" data-sentry-source-file="404.tsx">Sorry, we could not find the page you&apos;re looking for.</Typography.Title>
                        </div>
                        <div className="mb-20 md:mb-0">
                            <Button onClick={routeBack} type="success" className={backClicked ? 'disabled' : ''} data-sentry-element="Button" data-sentry-source-file="404.tsx">
                                Go back
                            </Button>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 text-center" />
                </div>
                <div className="w-64 md:w-96 h-96 md:h-full bg-primary dark:bg-primary-dark bg-opacity-25 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45" />
                <div className="w-96 h-full bg-primary dark:bg-primary-dark bg-opacity-20 absolute -bottom-96 right-64 rounded-full pointer-events-none -rotate-45" />
            </div>
        </AppLayout404>
    </>;
};
export default Custom404;